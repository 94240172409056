import React, { useRef, useState, useEffect, ReactNode, useCallback } from 'react';
import VideoContext from './VideoContext'
//@ts-ignore
import AiNumberReader from './AiNumberReader.worker'

interface VideoProviderProps {
  children: ReactNode;
}

const VideoProvider: React.FC<VideoProviderProps> = ({ children }) => {

  const [cameraState, setCameraState] = useState<string>('IDLE')
  const [selectedCamera, setSelectedCamera] = useState<string | null>(null)
  const [availableCameras, setAvailableCameras] = useState<MediaDeviceInfo[]>([])
  const [QRCodesPresent, setQRCodesPresent] = useState<any>(null)
  const [QRCodeSearchOptions, setQRCodesSearchOptions] = useState<any>(null)
  const [isFacingUser, setIsFacingUser] = useState<boolean>(false)
  const [straightenedImageUrl, setStraightenedImageUrl] = useState<string>('')
  const [straightenedImageNoQRUrl, setStraightenedImageNoQRUrl] = useState<string>('')
  const [aiRecognizerState, setAiRecognizerState] = useState<string>('')
  const [aiResults, setAiResults] = useState<any>({})


  const animationFrameHandleRef = useRef<number>(0) // this is needed for cancel
  const canvasesToStreamToRef = useRef<React.RefObject<HTMLCanvasElement>[]>([])
  

  const mediaStreamRef = useRef<MediaStream | null>(null)
  const frameSinceQRCodeSpotted = useRef<number>(0)
  const QRCodeSearchOptionsRef = useRef<any>(null)
  const videoStartLockRef = useRef<string | null>(null)
  const cameraStateRef = useRef<string | null>(null)

  const webWorkerRef = useRef<any>(null)
  const aiResultsRef = useRef<any>(null)

  const videoElementRef = useRef<HTMLVideoElement>(null)
  const rawVideoCanvasRef = useRef<HTMLCanvasElement>(null)
  const captureCanvasRef = useRef<HTMLCanvasElement>(null)
  const conversionCanvasRef = useRef<HTMLCanvasElement>(null)

  const barcodeModeRef = useRef<string>(null)
  const barcodeDetectorRef = useRef<any>(null)
  const isFacingUserRef = useRef<any>(null)


  useEffect(() => { QRCodeSearchOptionsRef.current = QRCodeSearchOptions }, [QRCodeSearchOptions])
  useEffect(() => { isFacingUserRef.current = isFacingUser }, [isFacingUser])
  useEffect(() => { aiResultsRef.current = aiResults }, [aiResults])
  useEffect(() => { cameraStateRef.current = cameraState }, [cameraState])


  useEffect(() => {
    if (webWorkerRef.current === null) {
      //console.log("Creating new web worker")
      const worker = new AiNumberReader()
      webWorkerRef.current = worker
      worker.onmessage = handleMessageFromWebWorker
      if ("BarcodeDetector" in window) {
        //@ts-ignore
        barcodeDetectorRef.current = new window.BarcodeDetector({
          formats: ["qr_code"]
        });
        //@ts-ignore
        barcodeModeRef.current = "browser"
      } else {
        //@ts-ignore
        barcodeModeRef.current = "jsqr"
      }
      const model_name = "march2024"

   //   worker.postMessage({ "action": "loadModel", "model": model_name })

    }

  }, [])

  useEffect(() => {
    function handleVisibilityChange() {
      if (document.hidden) {
        if(cameraStateRef.current === 'RUNNING'){
          stopCamera()
          setCameraState('PAUSED_TAB_INACTIVE')
          cameraStateRef.current = 'PAUSED_TAB_INACTIVE'
        }
        //console.log('Tab is inactive.');
      } else {
        //console.log('Tab is active.');
        if(cameraStateRef.current === 'PAUSED_TAB_INACTIVE'){
          startCamera()
          setCameraState('RUNNING')
          cameraStateRef.current = 'RUNNING'
        }
      }
    }
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return (() => {
     document.removeEventListener('visibilitychange', handleVisibilityChange);
    })

  }, [])



  useEffect(() => {
    const updateAvailableCameras = async () => {
      const videoDevices = await _getAvailableDevices()
      setAvailableCameras(videoDevices)
    }
    updateAvailableCameras()
    // some android phones (e.g. our Hauwei) change their mind about what cameras they have a short time after we first ask
    setTimeout(updateAvailableCameras, 1000)
    navigator.mediaDevices.ondevicechange = updateAvailableCameras
    return (() => {
      navigator.mediaDevices.removeEventListener('devicechange', updateAvailableCameras);
    })
  }, [])



const setExpectedReadingDetails = (digits_after_decimal: number, expected_max_temperature: number) => {
 // webWorkerRef.current.postMessage({ "action": "setExpectedReadingDetails", digits_after_decimal, expected_max_temperature })
}

const tick = useCallback((frameCount: number) => {

  const videoElement = videoElementRef.current!

  let newFrameCount = frameCount
  let isVideoPlayingRightNow = false
  if (canvasesToStreamToRef.current.length > 0) {
    newFrameCount = frameCount + 1
    isVideoPlayingRightNow = true
  }

  if (isVideoPlayingRightNow && videoElement.readyState === videoElement.HAVE_ENOUGH_DATA) {
    const video_width = videoElement.videoWidth
    const video_height = videoElement.videoHeight

    const rawVideoCanvas = rawVideoCanvasRef.current
    const raw_ctx = rawVideoCanvas!.getContext("2d")
    const qr_search_options = QRCodeSearchOptionsRef.current || {}
    const look_for_qr_codes = qr_search_options && qr_search_options['enabled']
    const takeStraightenedPhoto = qr_search_options && qr_search_options['takeStraightenedPhoto']

    if (look_for_qr_codes) {
      rawVideoCanvas!.width = video_width
      rawVideoCanvas!.height = video_height

      const searchArea = {
        left: 0,
        top: 0,
        width: video_width,
        height: video_height
      }

      if (qr_search_options && qr_search_options['searchArea']) {
        const scaleFactors = qr_search_options['searchArea']
        searchArea.top = searchArea.height * scaleFactors.top
        searchArea.left = searchArea.width * scaleFactors.left
        searchArea.width = searchArea.width * scaleFactors.width
        searchArea.height = searchArea.height * scaleFactors.height
      }

      raw_ctx!.drawImage(videoElement, 0, 0, rawVideoCanvas!.width, rawVideoCanvas!.height)
      if (raw_ctx) {
        const rawVideoImageData = raw_ctx!.getImageData(searchArea.left, searchArea.top, searchArea.width, searchArea.height)

        if (rawVideoImageData) {
          if (barcodeModeRef.current === 'browser') {
            barcodeDetectorRef.current.detect(rawVideoImageData).then((detector_result: any[]) => {
              if (detector_result.length !== 0) {
                webWorkerRef.current.postMessage({ 'action': 'findQRCode', 'imageData': rawVideoImageData, 'takeStraightenedPhoto': takeStraightenedPhoto })
              }
            })
          } else {
            webWorkerRef.current.postMessage({ 'action': 'findQRCode', 'imageData': rawVideoImageData, 'takeStraightenedPhoto': takeStraightenedPhoto })
          }
        }
      }
    }


    for (const canvasRef of canvasesToStreamToRef.current) {
      const canvas = canvasRef.current
      const ctx = canvas!.getContext("2d")
      if (ctx) {

        const canvas_width = canvas!.width
        const canvas_height = canvas!.height
        const video_aspect_ratio = video_width / video_height
        const canvas_aspect_ratio = canvas_width / canvas_height

        let render_width = canvas_width
        let render_height = canvas_width / video_aspect_ratio
        let cropped_video_width = video_width
        let cropped_video_height = video_height
        let x_adjust = 0
        let y_adjust = 0


        if (canvas_aspect_ratio < video_aspect_ratio) {
          render_height = canvas_height
          render_width = canvas_height * video_aspect_ratio
        }

        if (render_width > canvas_width) {
          const overflow_as_fraction = 1 - (canvas_width / render_width)
          cropped_video_width = video_width * (1 - overflow_as_fraction)
          x_adjust = (overflow_as_fraction / 2) * video_width
          render_width = canvas_width
        }
        if (render_height > canvas_height) {
          const overflow_as_fraction = 1 - (canvas_height / render_height)
          cropped_video_height = video_height * (1 - overflow_as_fraction)
          y_adjust = (overflow_as_fraction / 2) * video_height
          render_height = canvas_height
        }

        if (isFacingUserRef.current) {
          ctx.setTransform(-1, 0, 0, 1, render_width, 0);
        } else {
          ctx.setTransform(1, 0, 0, 1, 0, 0)
        }
        ctx.drawImage(videoElement, x_adjust, y_adjust, cropped_video_width, cropped_video_height, 0, 0, render_width, render_height)


        if (qr_search_options && qr_search_options['enabled'] && qr_search_options['searchArea'] && qr_search_options['showSearchArea']) {
          const scaleFactors = qr_search_options['searchArea']
          const searchArea = { top: 0, left: 0, width: 0, height: 0 }
          searchArea.top = Math.ceil(render_height * scaleFactors.top)
          searchArea.left = Math.ceil(render_width * scaleFactors.left)
          searchArea.width = Math.ceil(render_width * scaleFactors.width)
          searchArea.height = Math.ceil(render_height * scaleFactors.height)
          ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Red color with 50% opacity
          ctx.fillRect(0, 0, render_width, searchArea.top)
          ctx.fillRect(0, searchArea.height + searchArea.top, render_width, searchArea.height)
          ctx.fillRect(0, searchArea.top, searchArea.left, searchArea.height)
          ctx.fillRect(searchArea.left + searchArea.width, searchArea.top, (render_width - (searchArea.width + searchArea.left)), searchArea.height)

        }

      }
    }
  }
  animationFrameHandleRef.current = requestAnimationFrame(() => { tick(newFrameCount) })
}, [])

useEffect(() => {
  tick(0)
  return (() => { cancelAnimationFrame(animationFrameHandleRef.current) })
}, [tick]);


const getDomainFromURL = (urlString: string) => {
  if (urlString === "") {
    return ""
  }
  const url = new URL(urlString);
  const domain = url.hostname;
  return domain
}

const clearAiResults = () => {
  setAiResults({})
}

const handleMessageFromWebWorker = (message: any) => {
  const messageData = message.data
  const action = messageData.action
  const qr_search_options = QRCodeSearchOptionsRef.current!
  switch (action) {
    case 'qrCodeFound':
      //console.log(' QR code found')
      const code = messageData.code
      //console.log(code)
      const qr_code_image_data = messageData.imageData
      let domainValid = true
      if (qr_search_options && qr_search_options['expectedDomains']) {
        const qr_domain = getDomainFromURL(code.data)
        domainValid = false
        for (const validDomain of qr_search_options['expectedDomains']) {
          if (validDomain.toLowerCase() === qr_domain.toLowerCase()) {
            domainValid = true
          }
        }
      }
      if (domainValid && (cameraStateRef.current === 'RUNNING')) {
        setQRCodesPresent([messageData.code.data])
        frameSinceQRCodeSpotted.current = 0
        //console.log('💚')
        //console.log(JSON.stringify(qr_search_options))
        if (qr_search_options && qr_search_options['takeStraightenedPhoto']) {
          //console.log('💜')
          webWorkerRef.current.postMessage({ 'action': 'straightenQRCodeOntoCanvas', 'imageData': qr_code_image_data, 'code': code, 'readWithAi': qr_search_options && qr_search_options['attemptAiReading'] })
        }
      } else {
        //console.log(`Ignoring QR Code because camera state is ${cameraState}`)
      }

      break;
    case 'noQRCodeFound':
      frameSinceQRCodeSpotted.current = frameSinceQRCodeSpotted.current + 1
      if (frameSinceQRCodeSpotted.current === 30) {
        //console.log('💚')
        setQRCodesPresent(null)
      }
      break;
    case 'returnStraightendImage':
      const image_as_url = messageData.jpegString
      setStraightenedImageUrl(image_as_url)
      break;
    case 'returnStraightendImageNoQr':
      const image_no_qr_as_url = messageData.jpegString
      setStraightenedImageNoQRUrl(image_no_qr_as_url)
      break;
    case 'setAiRecognizerState':
      setAiRecognizerState(messageData.value)
      break;
    case 'setAiResult':
      const oldResults = aiResultsRef.current || {}
      const newAiResults = JSON.parse(JSON.stringify(oldResults))
      const thisResult = messageData.result
      const newResultConfidence = thisResult.confidence
      const assetId = thisResult.assetId
      const unCroppedImageData = thisResult.uncropped_image_data
      const croppedImageData = thisResult.cropped_image_data
      const oldResultConfidence = (oldResults && oldResults[assetId] && oldResults[assetId]['confidence']) || 0

      if (newResultConfidence > oldResultConfidence) {
        thisResult['uncroppedJpeg'] = imageDataAsJpegString(unCroppedImageData)
        thisResult['croppedJpeg'] = imageDataAsJpegString(croppedImageData)
        delete thisResult.uncropped_image_data
        delete thisResult.cropped_image_data
        newAiResults[assetId] = thisResult
        setAiResults(newAiResults)
      } else {
        //console.log(`rejecting reading: ${newResultConfidence}`)
      }

      break;

    default:
      console.log(`got unknown message from worker ${action}`)
      break;
  }
}

const _getAvailableDevices = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(device => device.kind === 'videoinput');
  return videoDevices
}


const chooseCameraAndGetVideoConstraints = (videoDevices: MediaDeviceInfo[]) => {
  const screen_height = window.screen.height  //  window.innerHeight
  const screen_width = window.screen.width
  let window_width = window.innerWidth
  const screen_aspect_ratio = screen_width / screen_height
  //   const aspect_ratio_to_use = (screen_height > screen_width) ? 1 / screen_aspect_ratio : screen_aspect_ratio
  const aspect_ratio_to_use = 1
  window_width = window_width * 2
  const max_width = 800
  if (window_width > max_width) {
    window_width = max_width
  }
  let deviceId = videoDevices[videoDevices.length - 1] && videoDevices[videoDevices.length - 1]['deviceId']
  if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
    if (videoDevices.length >= 2) {
      deviceId = videoDevices[1]['deviceId']
    }
  }

  const options: any = {
    width: { 'max': window_width },

    aspectRatio: { 'exact': aspect_ratio_to_use }
  }

  const validDeviceIds = []
  for (const device of availableCameras) {
    validDeviceIds.push(device.deviceId)
  }

  const deviceFromLocalStorage = localStorage.getItem('selectedVideoInputDevice')
  if (deviceFromLocalStorage && validDeviceIds.includes(deviceFromLocalStorage)) {
    options['deviceId'] = { 'exact': deviceFromLocalStorage }
  } else {
    if (deviceId && deviceId !== '') {
      options['deviceId'] = { 'exact': deviceId }
    }
    options['facingMode'] = 'environment'
  }

  return options
}

const clearCameraStateVariables = () => {
  setQRCodesPresent(null)
  setStraightenedImageNoQRUrl('')
  setStraightenedImageUrl('')
  setAiResults({})
}

const stopCamera = () => {
  if (cameraStateRef.current !== 'IDLE') {
    cameraStateRef.current = 'IDLE'
    setCameraState('IDLE')
    clearCameraStateVariables()
    const stream = mediaStreamRef.current
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
  }
}

const handleTrackEnded = (event: Event) => {
  //console.log(`🍍 🍍 🍍 🍍 🍍 Video track ended detected ${event}`)
  if (cameraState === 'RUNNING') {
    startCamera()
  }
}

const startCamera = async () => {
  // set state to starting as theres some async stuff going on and this funciton will return before the video has actually started
  const array = new Uint32Array(4);
  window.crypto.getRandomValues(array);
  const myLockId = JSON.stringify(array)
  if (videoStartLockRef.current === null) {
    videoStartLockRef.current = myLockId
  } else {
    return
  }

  setCameraState('STARTING')
  clearCameraStateVariables()

  let videoConstraints = null
  // use get user media to get camera stream
  videoConstraints = chooseCameraAndGetVideoConstraints(availableCameras)
  setSelectedCamera(videoConstraints['deviceId'])
  //console.log("Trying to get camera with id ", videoConstraints['deviceId'])
  //console.log(videoConstraints)
  await navigator.mediaDevices.getUserMedia({ video: videoConstraints }).then(async function (stream) {
    if (videoStartLockRef.current !== myLockId) {
      return
    }
    const videoElement = videoElementRef.current
    mediaStreamRef.current = stream
    videoElement!.srcObject = stream
    videoElement!.setAttribute("playsinline", "true") // required to tell iOS safari we don't want fullscreen
    if (videoStartLockRef.current !== myLockId) {
      return
    }
    //console.log(`▶️ Starting video ${cameraState} ${Date.now()}`)
    const playPromise = videoElement!.play()
    if (playPromise !== undefined) {
      playPromise.then(_ => {
        setCameraState('RUNNING')
        cameraStateRef.current = 'RUNNING'
        videoStartLockRef.current = null
        let cameraDeviceId = null
        let facingUser = false
        stream.getTracks().forEach(track => {

          const capabilities = track.getCapabilities();
          const settings = track.getSettings();

          if ((capabilities && capabilities.facingMode && capabilities.facingMode.includes('user')) || (capabilities && capabilities.facingMode && capabilities.facingMode.length === 0)) {
            facingUser = true
          }
          cameraDeviceId = settings.deviceId
          //console.log("Got camera", cameraDeviceId)

          track.addEventListener('ended', (event) => { handleTrackEnded(event) });
          setSelectedCamera(`${cameraDeviceId}`)
        });
        if (facingUser) {
          setIsFacingUser(true)
        } else {
          setIsFacingUser(false)
        }
      })
    }

  })

}

const streamToMyCanvas = (canvasRef: React.RefObject<HTMLCanvasElement>) => {
  //console.log('✅ STARTING THE CAMERA')
  if (canvasesToStreamToRef.current.length === 0) {
    startCamera();
  }
  if (canvasesToStreamToRef.current.indexOf(canvasRef) === -1) {
    canvasesToStreamToRef.current.push(canvasRef)
  }
}

const stopStreamingToMyCanvas = (canvasRef: React.RefObject<HTMLCanvasElement>) => {
  //console.log('⛔️ STOPPING THE CAMERA')
  canvasesToStreamToRef.current = canvasesToStreamToRef.current.filter(function (e) { return e !== canvasRef })
  if (canvasesToStreamToRef.current.length === 0) {
    stopCamera()
  }
}

const selectCamera = (cameraId: string) => {
  console.log("Changing camera")
  if (cameraId !== selectedCamera) {
    localStorage.setItem('selectedVideoInputDevice', cameraId)
    if (cameraState === 'RUNNING') {
      setCameraState('SWITCHING')
      stopCamera()
      startCamera()
      setCameraState('RUNNING')
    }
  }
}


const imageDataAsJpegString = (imageData: ImageData) => {
  const conversionCanvas = conversionCanvasRef.current!
  conversionCanvas.width = imageData.width
  conversionCanvas.height = imageData.height
  const conversionCanvasCtx = conversionCanvas.getContext("2d")
  conversionCanvasCtx!.putImageData(imageData, 0, 0)
  const imageString = conversionCanvas?.toDataURL('image/jpeg')
  return imageString
}


const captureAsJPEGString = (width: number | undefined = undefined) => {
  const rawVideoCanvas = rawVideoCanvasRef.current
  const captureCanvas = captureCanvasRef.current
  if (!rawVideoCanvas) {
    return undefined
  }
  if (width === undefined) {
    const imageString = rawVideoCanvas?.toDataURL('image/jpeg')
    return imageString
  } else {
    const scale_factor = width / rawVideoCanvas.width
    captureCanvas!.width = width
    captureCanvas!.height = (rawVideoCanvas.height * scale_factor)
    const captureCanvasCtx = captureCanvas?.getContext("2d")
    captureCanvasCtx!.drawImage(rawVideoCanvas, 0, 0, rawVideoCanvas.width, rawVideoCanvas.height, 0, 0, captureCanvas!.width, captureCanvas!.height)
    const imageString = captureCanvas?.toDataURL('image/jpeg')

    // console.log(`
    // scaling captured image from
    // ${rawVideoCanvas.width}x${rawVideoCanvas.height} (${rawVideoCanvas.width/rawVideoCanvas.height})
    // to:
    // ${captureCanvas!.width}x${captureCanvas!.height} (${captureCanvas!.width/captureCanvas!.height}`)

    return imageString
  }
}


return (
  <VideoContext.Provider value={{
    stopStreamingToMyCanvas,
    streamToMyCanvas,
    availableCameras,
    selectCamera,
    selectedCamera,
    cameraState,
    QRCodesPresent,
    setQRCodesPresent,
    setQRCodesSearchOptions,
    captureAsJPEGString,
    straightenedImageUrl,
    straightenedImageNoQRUrl,
    aiRecognizerState,
    aiResults,
    clearAiResults,
    clearCameraStateVariables,
    setExpectedReadingDetails
  }}>
    <div className={'hidden'}>
      <video
        ref={videoElementRef}
        autoPlay={true}
        muted={true}
        playsInline={true}
      // style={{ outline: '2px solid red' }}
      ></video>
      <canvas ref={rawVideoCanvasRef}></canvas>
      <canvas ref={captureCanvasRef}></canvas>
      <canvas ref={conversionCanvasRef}></canvas>

    </div>

    {children}

  </VideoContext.Provider>
);
};

export default VideoProvider;
