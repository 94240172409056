import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GlobalContext } from '../GlobalContext'
import Button from '../formfields/Button'
import HeaderDetails from '../shared-components/HeaderDetails'
import RedAlert from '../ui-elements/RedAlert'
import { table } from 'console'
import Spinner from '../ui-elements/Spinner'
import { subscribe } from '../utils/pubsub'
import Card from '../ui-elements/Card'
import GreenAlert from '../ui-elements/GreenAlert'
import YellowAlert from '../ui-elements/YellowAlert'
import JsonDebug from '../ui-elements/JsonDebug'
import ProgressBar from '../ui-elements/ProgressBar'



type Props = {
    thisProcessData: ObjectStringKeyAnyValue
}

function ProcessStageIndicator({
    thisProcessData
}: Props) {


    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal,
        processData
    } = useContext(GlobalContext)


    const currentStageId = thisProcessData && thisProcessData.processRecord && thisProcessData.processRecord.CurrentStageId
    const stages = thisProcessData && thisProcessData.Stages
    const orderedStageIds = Object.keys(stages).sort((a: string, b: string) => { return stages[a].Order - stages[b].Order })
    const progressPercentage = (orderedStageIds.indexOf(currentStageId) + 1) / orderedStageIds.length * 100
    const maxNumberOfStagesToShowHorizontally = 6


    if (!currentStageId) {
        return null
    }


    return <div className={`w-full bg-white rounded`}>


        <div className={`
        ${orderedStageIds.length > maxNumberOfStagesToShowHorizontally ? ` flex` : ` md:hidden flex `}
            w-full flex-col gap-2`}>
            <ProgressBar percentage={progressPercentage} />
                <div className={`flex sm:flex-row flex-col sm:gap-1 items-center justify-center`}>
                    <p className={``}>Current stage ({orderedStageIds.indexOf(currentStageId) + 1} of {orderedStageIds.length}): </p>
                    <p className={`font-bold text-xl`}>{stages[currentStageId].DisplayName}</p>
                </div>
        </div>


        <div className={`
${orderedStageIds.length > maxNumberOfStagesToShowHorizontally ? ` hidden` : ` md:flex hidden `}
            w-full flex-row
            `}>
            {orderedStageIds.map((stageId, index) => {
                const thisStage = stages[stageId]
                const stageName = thisStage.DisplayName || `Stage ${index + 1}`
                const isCurrent = currentStageId === stageId
                const isBeforeCurrent = orderedStageIds.indexOf(stageId) < orderedStageIds.indexOf(currentStageId)
                const isFirst = index === 0
                const isLast = index + 1 === Object.keys(stages).length

                return <div
                    key={index}
                    className={`
                    px-4 py-1 w-full text-center 
                    ${isFirst ? `rounded-l` : ``}
                    ${isLast ? `rounded-r` : ``}
                    ${!isLast ? `  ` : ``}
                    ${isCurrent ? ` font-bold text-white bg-brandblue ` : ``} 
                    ${isBeforeCurrent ? `  bg-tealgreen text-brandblue ` : ``} 
                    ${!isBeforeCurrent && !isCurrent ? `  bg-gray-300 text-gray-600 ` : ``} 
                `}>
                    {isBeforeCurrent && <span className={`text-xs hidden`}>✅</span>} {stageName}
                </div>
            })}

        </div>
    </div>

}
export default ProcessStageIndicator