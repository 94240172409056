export const getChecklistProgress = (checkPeriodId, tableData) => {
    // empty return if parameters are empty
    if (
        !checkPeriodId ||
        !tableData ||
        !tableData['ChecklistPeriods'] ||
        !tableData['ChecklistPeriods'][checkPeriodId]
    ) {
        console.log(`⛔️ error finding checklist period: ${checkPeriodId} from tableData`)
        return null
    }

    let numberOfExpectedAnswers = 0
    let numberOfCompletedAnswers = 0
    for (const answerId in tableData.ChecklistAnswers) {
        const thisAnswer = tableData.ChecklistAnswers[answerId]
        if (thisAnswer.ChecklistPeriodId === checkPeriodId) {
            numberOfExpectedAnswers++
            if (!thisAnswer.Pending) {
                numberOfCompletedAnswers++
            }
        }
    }

    if (numberOfExpectedAnswers === 0) {
        // console.log(`⛔️ no questions for checklist period ${checkPeriodId}`)
        return null
    }

    return ({ numberOfExpectedAnswers, numberOfCompletedAnswers })
}