



import { useContext } from "react"
import { GlobalContext } from "../GlobalContext"
import Modal from "./Modal"
import Button from "../formfields/Button"
import Spinner from "./Spinner"

type Props = {
    showModal: ObjectStringKeyAnyValue
}


function MessageModal({
    showModal
}: Props) {

    const {
        setShowModal
    } = useContext(GlobalContext)



    return <Modal setShowModal={setShowModal} showCloseButton={true} closeButtonValue={null}>
        <div className={`w-full flex flex-col gap-4 items-center`}>

            {showModal["error"] && <div className={`w-full flex flex-col gap-2 items-center`}>
                <p className={`text-4xl`}>⛔️</p>
                <p className={``}>Error: {showModal["error"]}</p>
            </div>}

            {showModal["message"] && <div className={`w-full flex flex-col gap-2 items-center`}>
                <p className={`text-4xl`}>💬</p>
                <p className={``}>{showModal["message"]}</p>
            </div>}

            {showModal["spinner"] && <div className={`w-full flex flex-col gap-2 items-center`}>
                <Spinner size="large">{showModal["spinner"]}</Spinner>
            </div>}



            {!showModal["spinner"] && <Button
                onClick={() => setShowModal(null)}
                text={`OK`}
                variant={`primary`}
                size={`big`}
                fullwidth={true}
            />}
        </div>
    </Modal>
}

export default MessageModal