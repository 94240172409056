
import React, { useContext, Children } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useParams } from 'react-router-dom'
import YellowAlert from '../ui-elements/YellowAlert'
import FormatQuestionTitle from '../dashboard/FormatQuestionTitle'



type Props = {
  titleText?: string
  instructionsText?: string
  subheadingText?: string | null
  thisQuestion?: ObjectStringKeyAnyValue | null
}

function HeaderDetails({
  titleText,
  instructionsText,
  subheadingText,
  thisQuestion
}: Props) {

  const {
    isVideoMode
  } = useContext(GlobalContext)




  return <div className={`
  ${isVideoMode ? ' fixed w-full max-w-3xl px-5 ' : ' w-full '}`}>


    <div className={`
    ${isVideoMode ? ' mt-4 rounded ' : ' border-b-2 border-gray-300 '}
    w-full flex flex-row gap-4 items-center justify-center 
    `}
      style={{
        background: `rgba(255, 255, 255, ${isVideoMode ? '0.8' : '1'})`
      }}
    >

      <div className={`max-w-3xl w-full px-5 py-2 flex flex-col gap-0 items-center justify-center text-center`}>


        {titleText && !thisQuestion && <h3 className={`text-3xl font-righteous text-brandblue`}>{titleText}</h3>}

        {thisQuestion && <FormatQuestionTitle question={thisQuestion} classString={`text-3xl font-righteous text-brandblue`} />}


        {subheadingText && <p className={`mt-2 w-full text-md text-gray-600 italic`}>
          {subheadingText}</p>}

        {instructionsText && <p className={`mt-2 w-full text-md text-gray-600 italic`}>
          {instructionsText}</p>}

        {/* {instructionsText && <div className={`mt-2 w-full`}><YellowAlert size='small' icon={false}>{instructionsText}</YellowAlert></div>} */}


      </div>
    </div>
  </div>
}
export default HeaderDetails