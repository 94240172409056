import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GlobalContext } from '../GlobalContext'
import Button from '../formfields/Button'
import HeaderDetails from '../shared-components/HeaderDetails'
import RedAlert from '../ui-elements/RedAlert'
import { table } from 'console'
import Spinner from '../ui-elements/Spinner'
import { subscribe } from '../utils/pubsub'
import Card from '../ui-elements/Card'
import GreenAlert from '../ui-elements/GreenAlert'
import YellowAlert from '../ui-elements/YellowAlert'



type Props = {
}

function ProcessList({
}: Props) {


    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal,
        processData
    } = useContext(GlobalContext)


    const [hasFetchedData, setHasFetchedData] = useState(false)


    useEffect(() => {
        if (!hasFetchedData) {
            fetchData()
        }
    }, [hasFetchedData])

    const fetchData = () => {
        console.log('fetching data')
        let requestAssets = JSON.stringify({
            action: "processesClient",
            subAction: "fetchProcesses",
        })
        sendMessageToWebsocket(requestAssets)
    }





    return <div className={`w-full flex-1 h-full flex flex-col items-center gap-2`}>

        <HeaderDetails
            titleText={`Processes`}
        />

        <div className={`max-w-3xl w-full px-5 py-5 flex flex-col gap-4`}>


            {processData && <div className={`flex flex-col gap-2`}>{Object.keys(processData).map((processDefinitionId: string, index: number) => {
                const processDefinition = processData[processDefinitionId]
                const processRecord = processData[processDefinitionId].processRecord
                const currentStageId = processRecord && processRecord.CurrentStageId
                const currentStageName = currentStageId && processDefinition && processDefinition.Stages && processDefinition.Stages[currentStageId] && processDefinition.Stages[currentStageId].DisplayName
                return <Card key={index}>
                    <div className={`w-full flex flex-col gap-2 items-start`}>

                        <h4 className={`text-lg font-righteous text-brandblue`}>{processData[processDefinitionId].DisplayName}</h4>

                        {currentStageName && <YellowAlert fullwidth={true} alignment={`left`}>⏰ Currently in stage: {currentStageName}</YellowAlert>}
 
                    </div>
                    <Button
                        text={`View`}
                        internalLinkUrl={`/process/${processDefinitionId}`}
                        // size='big'
                        fullwidth={true}
                        variant='primary'
                    />
                </Card>
            })}</div>}

            {!processData && <Spinner size='large'>Loading...</Spinner>}
        </div>
    </div>

}
export default ProcessList