import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GlobalContext } from '../GlobalContext'
import Button from '../formfields/Button'
import HeaderDetails from '../shared-components/HeaderDetails'
import RedAlert from '../ui-elements/RedAlert'
import { table } from 'console'
import Spinner from '../ui-elements/Spinner'
import { subscribe } from '../utils/pubsub'
import { json } from 'stream/consumers'
import ChecklistSummary from '../dashboard/ChecklistSummary'
import YellowAlert from '../ui-elements/YellowAlert'
import Card from '../ui-elements/Card'



type Props = {
}

function ProcessSummaryCheckPeriods({
}: Props) {

    const { processId } = useParams()
    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal,
        processData
    } = useContext(GlobalContext)


    const thisProcessData = processId && processData && processData[processId]
    const inProgress = thisProcessData && thisProcessData.InProgress

    const pendingCheckPeriods = thisProcessData && thisProcessData.PendingCheckPeriods
    const completedCheckPeriods = thisProcessData && thisProcessData.CompletedCheckPeriods

    const totalCheckPeriods = (pendingCheckPeriods && pendingCheckPeriods.length) + (completedCheckPeriods && completedCheckPeriods.length)

    if (totalCheckPeriods === 0) {
        return null
    }

    return <div className={`w-full flex-1 h-full flex flex-col items-center gap-2`}>
        {pendingCheckPeriods && pendingCheckPeriods.length > 0 && <>
        <h4 className={`text-xl font-righteous text-brandblue`}>Checklists to complete:</h4>
        <div className={`w-full flex flex-col gap-2`}>
            {pendingCheckPeriods && pendingCheckPeriods.map((thisCheckPeriod: any, index: number) => {
                const checklistId = thisCheckPeriod && thisCheckPeriod.ChecklistId
                const thisChecklist = tableData && tableData.Checklists && tableData.Checklists[checklistId]
                return <ChecklistSummary
                    key={index}
                    checklistId={checklistId}
                    thisChecklist={thisChecklist}
                    checkPeriodId={thisCheckPeriod.Id}
                />
            })}
        </div>
        </>}
        {completedCheckPeriods && completedCheckPeriods.length > 0 && <div className={`w-full flex flex-col gap-2 items-center`}>
            <h4 className={`text-xl font-righteous text-brandblue`}>Completed checklists:</h4>

            <div className={`w-full`}>
             <Card fullwidth={true}><ul>
            {completedCheckPeriods.map((thisCheckPeriod: any, index: number) => {
                const checklistId = thisCheckPeriod && thisCheckPeriod.ChecklistId
                const thisChecklist = tableData && tableData.Checklists && tableData.Checklists[checklistId]
                return <li key={index} className={`w-full flex flex-col gap-2`}>{"✅ "}
                    {thisCheckPeriod.StartHoursMinsDay.hours.toString().padStart(2, '0')} :
                    {thisCheckPeriod.StartHoursMinsDay.minutes.toString().padStart(2, '0')}{" "}
                    {thisChecklist.ChecklistName}
                </li>
            })}
            </ul></Card>    
        </div>
        </div>}
    </div>



}
export default ProcessSummaryCheckPeriods