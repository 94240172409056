
import React, { useContext, Children } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'



type Props = {
  questionId?: string
  checklistId?: string
  setQuestionAnswer: React.Dispatch<React.SetStateAction<any>>
  defaultValue: null | string | string[]
}

function QuestionNumber({
  questionId,
  checklistId,
  setQuestionAnswer,
  defaultValue
}: Props) {

  const {
    tableData
  } = useContext(GlobalContext)

  const thisQuestion = questionId && tableData && tableData['ChecklistQuestions'] && tableData['ChecklistQuestions'][questionId]

  // let numbersAndDecimalRegexString = "/^\d*\.?\d*$/"
  // let numbersAndDecimalRegex = new RegExp(numbersAndDecimalRegexString)

  // const updateIfValidNumber = (value: string) => {
  //   if (numbersAndDecimalRegex.test(value)) { // allow only numbers and a single decimal point
  //     setQuestionAnswer(value);
  //   }
  // }


  return <div className={`w-full flex flex-row gap-2 items-center`}>
    <input
      className={`bg-white text-center drop-shadow-lg border border-gray-300 text-gray-900 rounded hover:opacity-90-lg focus:ring-blue-500 focus:border-blue-500 block w-full text-lg px-5 py-3`}
      onChange={(e) => setQuestionAnswer(e.target.value)}
      type={`number`}
      inputMode="decimal"
      // pattern={numbersAndDecimalRegexString}
      value={defaultValue || ''}
    />

    {thisQuestion && thisQuestion.MeasurementUnit && <p>{thisQuestion.MeasurementUnit}</p>}

  </div>
}

export default QuestionNumber

