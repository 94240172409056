import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GlobalContext } from '../GlobalContext'
import Button from '../formfields/Button'
import HeaderDetails from '../shared-components/HeaderDetails'
import RedAlert from '../ui-elements/RedAlert'
import { table } from 'console'
import Spinner from '../ui-elements/Spinner'
import { subscribe } from '../utils/pubsub'
import ProcessSummaryCheckPeriods from './ProcessSummaryCheckPeriods'
import YellowAlert from '../ui-elements/YellowAlert'
import ProcessStageIndicator from './ProcessStageIndicator'



type Props = {
}


// countdown timer compoentent - gets given time of next check in milliseconds as property and 
// counts down in minutes, updating every 20 seconds or so

function CountdownTimer({
    timeToNextCheck
}: { timeToNextCheck: number }) {

    const [minutesToNextCheck, setMinutesToNextCheck] = useState<number | null>(null)
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (timerId) {
            clearInterval(timerId)
        }
        const myTimerId = setInterval(() => {
            updateMinutesToNextCheck(timeToNextCheck)
        }, 20000)

        setTimerId(myTimerId)
        updateMinutesToNextCheck(timeToNextCheck)

        return () => {
            timerId && clearInterval(timerId)
        }
    }, [timeToNextCheck])

    function updateMinutesToNextCheck(timestamp_utc_milliseconds: number) {
        //  local time might not be utc, need to get time in utc timezone
        //const now = new Date().getTime() + new Date().getTimezoneOffset() * 60000
        const now = Date.now()
        const minutes = Math.floor((timestamp_utc_milliseconds - now) / 60000)
        setMinutesToNextCheck(minutes)
    }



    //cancel timer when component unmounts

    if (minutesToNextCheck && minutesToNextCheck > 1) {
        return <YellowAlert fullwidth={true}>⏰ Next checks due in {minutesToNextCheck} minutes</YellowAlert>
    } else {
        return <YellowAlert fullwidth={true}>⏰ Next checks due soon</YellowAlert>

    }
}

function ProcessSummary({
}: Props) {

    const { processId } = useParams()
    const navigate = useNavigate()
    const {
        tableData,
        sendMessageToWebsocket,
        setShowModal,
        processData
    } = useContext(GlobalContext)
    const [pendingProcessDefinitionIds, setPendingProcessDefinitionIds] = useState<string[]>([])
    const [requestedProcessDefinitionIds, setRequestedProcessDefinitionIds] = useState<string[]>([])

    useEffect(() => {
        if (
            tableData && processId &&
            (!tableData.ProcessDefinitions || !tableData.ProcessDefinitions[processId])) {
            setPendingProcessDefinitionIds([...pendingProcessDefinitionIds, processId])
        }
    }, [tableData])


    useEffect(() => {
        if (pendingProcessDefinitionIds && pendingProcessDefinitionIds.length > 0) {
            for (const processId of pendingProcessDefinitionIds) {
                if (!requestedProcessDefinitionIds.includes(processId)) {
                    fetchProcessDefinition(processId)
                    setRequestedProcessDefinitionIds([...requestedProcessDefinitionIds, processId])
                }
            }
        }
    }, [pendingProcessDefinitionIds])

    const fetchProcessDefinition = (processId: string) => {
        let requestAssets = JSON.stringify({
            action: "processesClient",
            subAction: "fetchProcessDefinition",
            processId: processId
        })
        sendMessageToWebsocket(requestAssets)
    }

    const startProcess = () => {
        setShowModal({ 'spinner': 'Starting process...' })
        let payload = JSON.stringify({
            action: "processesClient",
            subAction: "startProcess",
            Id: processId
        })
        sendMessageToWebsocket(payload)

        const unsubscribeUpdate = subscribe("processStarted", data => {
            if (data && data.Id === processId) {
                setShowModal(null)
                if (data.nextChecklistPeriod) {
                    navigate(`/checklist/${data.nextChecklistPeriod}`)
                }
            }
            unsubscribeUpdate()
        })

    }

    const runNextStep = (nextStageId: string, processRecordId: string) => {
        setShowModal({ 'spinner': 'Loading...' })
        let payload = JSON.stringify({
            action: "processesClient",
            subAction: "processNextStepClicked",
            nextStageId: nextStageId,
            processRecordId: processRecordId
        })
        sendMessageToWebsocket(payload)

        const unsubscribeUpdate = subscribe("startedNextStep", data => {

            if (data && data.nextStageId === nextStageId && data.processRecordId === processRecordId) {
                setShowModal(null)
                if (data.nextChecklistPeriod) {
                    navigate(`/checklist/${data.nextChecklistPeriod}`)
                }
            }
            unsubscribeUpdate()
        })
    }



    const thisProcessData = processId && processData && processData[processId]
    const currentStageId = thisProcessData && thisProcessData['processRecord'] && thisProcessData['processRecord']['CurrentStageId']
    const thisStageProcessRecord = thisProcessData && thisProcessData['processRecord'] && thisProcessData['processRecord']['StageProgress'][currentStageId]
    const nextChecksDue = thisStageProcessRecord && thisStageProcessRecord['NextRepetitionTime']

  

    if (!thisProcessData) {
        return <div className={`w-full flex-1 h-full flex flex-col items-center gap-2`}>
            <div className={`max-w-3xl w-full px-5 py-5 flex flex-col gap-4`}>
                <Spinner size='large'>Loading...</Spinner>
            </div>
        </div>
    }

    return <div className={`w-full flex-1 h-full flex flex-col items-center gap-2`}>

        <div className={`w-full flex flex-row gap-4 items-center bg-white justify-center border-b-2 border-gray-300`} >
            <div className={`max-w-3xl w-full px-5 py-2 flex flex-col gap-4 items-center justify-center text-center`}>
                <h3 className={`text-3xl font-righteous text-brandblue`}>{thisProcessData.DisplayName}</h3>
                <ProcessStageIndicator
                    thisProcessData={thisProcessData}
                />
            </div>
        </div>



        <div className={`max-w-3xl w-full px-5 py-5 flex flex-col gap-4`}>

            <div className={`w-full flex flex-col gap-6 items-center`}>

                {thisProcessData.InProgress === false && <Button
                    onClick={startProcess}
                    text={`Start process`}
                    fullwidth={true}
                    size={`big`}
                    variant={`primary`}
                />}


                {nextChecksDue && <CountdownTimer timeToNextCheck={nextChecksDue} />}

               

                {thisProcessData.InProgress === true && <ProcessSummaryCheckPeriods />}

                {thisProcessData.NextSteps && Object.keys(thisProcessData.NextSteps).length > 0 && <div className={`w-full flex flex-col gap-2 items-center`}>

                    <h4 className={`text-xl font-righteous text-brandblue`}>Next steps:</h4>

                    {Object.keys(thisProcessData.NextSteps).map((step_id: any, index: number) => {
                        const thisStep = thisProcessData.NextSteps[step_id]
                        return <Button
                            key={index}
                            onClick={(e: any) => { runNextStep(step_id, thisProcessData.processRecord.Id) }}
                            text={thisStep.NextStepName}
                            fullwidth={true}
                            size={`big`}
                            variant={`primary`}
                        />
                    })}
                </div>}
            </div>
        </div>
    </div>

}
export default ProcessSummary