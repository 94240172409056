import React, { useEffect, useState, useContext } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Button from '../formfields/Button'
import { GlobalContext } from '../GlobalContext'
import FooterNavButtons from '../shared-components/FooterNavButtons'
import HeaderDetails from '../shared-components/HeaderDetails'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import { subscribe } from '../utils/pubsub'
import Modal from '../ui-elements/Modal'
import ChecklistSummary from './ChecklistSummary'
import OrgMismatchDialog from '../shared-components/OrgMismatchDialog'


type Props = {
    orgMismatchDialog: string | null
    setOrgMismatchDialog: React.Dispatch<React.SetStateAction<any>>
}

type AssetPickerProps = {
    assetTypeId: string,
    checklistId: string,
    startAdhocChecklist: () => void
}

function StartChecklist({
    orgMismatchDialog,
    setOrgMismatchDialog
}: Props) {

    const {
        tableData,
        setShowModal,
        sendMessageToWebsocket,
        userData,
        loginOptions,
        currentOrganisation
    } = useContext(GlobalContext)

    const navigate = useNavigate()
    const { checklistId, assetId } = useParams()
    const [thisChecklist, setThisChecklist] = useState<ObjectStringKeyAnyValue | null>(null)
    const [checkPeriodsForChecklist, setCheckPeriodsForChecklist] = useState<ObjectStringKeyAnyValue | null>(null)
    const [showNotFound, setShowNotFound] = useState(false)
    const [thisChecklistQuestions, setThisChecklistQuestions] = useState<null | Array<ObjectStringKeyAnyValue>>(null)
    const [assetIdNeeded, setAssetIdNeeded] = useState(assetId ? false : true)
    const checklistFrequency = thisChecklist && thisChecklist['Schedule'] && thisChecklist['Schedule']['Frequency'] ? thisChecklist['Schedule']['Frequency'] : null


    let titleText = thisChecklist && thisChecklist['ChecklistName'] ? thisChecklist['ChecklistName'] : 'Checklist'


    if (assetId) {
        let asset_name = ''
        if (tableData && tableData['Assets'] && tableData['Assets'][assetId]) {
            asset_name = tableData['Assets'][assetId]['AssetName']
        } else {
            console.log("asset name not found for asset id", assetId)
            //     const payload = JSON.stringify({
            //     action: "checklistsClientApp",
            //     subAction: "getAssetsByChecklistId",
            //     checklistId: checklistId,
            // })
            // sendMessageToWebsocket(payload)
        }
        titleText = `${titleText} for ${asset_name}`
    }



    useEffect(() => {
        if (thisChecklist) {
            if (Object.keys(thisChecklist).indexOf('AssetTypeId') === -1) {
                setAssetIdNeeded(false)
            }
        }
    }, [thisChecklist])


    useEffect(() => {
        console.log("StartChecklist.tsx useEffect")
        console.log(assetId)

        if (checklistId && tableData && tableData.Checklists && userData) {

            if (tableData.Checklists[checklistId]) {
                setThisChecklist(tableData.Checklists[checklistId])
                setShowNotFound(false)
            } else {
                // console.log('⛔️ Checklist not found. Orgs for this user:')
                // console.log(userData.organisationsForUser)]
                // console.log(currentOrganisation)
                setShowNotFound(true)
            }

            if (tableData['ChecklistQuestions']) {
                let questionsArray: any[] = Object.values(tableData['ChecklistQuestions']).filter((question: any) => question.ChecklistId === checklistId)
                questionsArray.sort((a, b) => parseInt(a.QuestionOrder) - parseInt(b.QuestionOrder))
                setThisChecklistQuestions(questionsArray)
            }

            // filter check periods for this checklist
            const newCheckPeriodsForChecklist: ObjectStringKeyAnyValue = {}
            if (tableData && tableData.ChecklistPeriods) {
                for (const [key, value] of Object.entries(tableData.ChecklistPeriods)) {
                    if ((value as any).ChecklistId === checklistId) {
                        if (!assetId || (assetId == (value as any)['AssetId'])) {
                            newCheckPeriodsForChecklist[key] = value
                        }
                    }
                }
                setCheckPeriodsForChecklist(newCheckPeriodsForChecklist)
            }

        }

    }, [checklistId, tableData, userData, assetId])


    const startAdhocChecklist = () => {
        setShowModal({ "spinner": 'Saving...' })
        const payload = JSON.stringify({
            action: "checklistsClientApp",
            subAction: "startAdhocChecklist",
            checklistId: checklistId,
            assetId: assetId,
        })
        sendMessageToWebsocket(payload)

        // when table data is updated with a check period for this checklist,
        // navigate to the checklist
        const unsubscribe = subscribe("tableUpdateItemAdded", data => {
            if (
                data.tableName === 'ChecklistPeriods' &&
                data.newRecord &&
                data.newRecord.Id &&
                data.newRecord.ChecklistId === checklistId
            ) {
                setShowModal(null)
                navigate(`/checklists/${data.newRecord.Id}`)
                unsubscribe()
            }
        })
    }

    return <div className={`w-full flex-1 h-full flex flex-col justify-between gap-2`}>


        {orgMismatchDialog && <OrgMismatchDialog
            orgMismatchDialog={orgMismatchDialog}
            setOrgMismatchDialog={setOrgMismatchDialog}
        />}




        <div className={`w-full flex flex-col items-center`}>
            <HeaderDetails
                titleText={titleText}
                subheadingText={assetId}
            />
            <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}>

                {!tableData && <Spinner><p>Loading...</p></Spinner>}
                {showNotFound && <RedAlert>Checklist {checklistId} not found</RedAlert>}
                {thisChecklist && thisChecklistQuestions && thisChecklistQuestions.length === 0 && <RedAlert icon={true}>No questions have been set up for this checklist</RedAlert>}





                {thisChecklist && checklistFrequency === 'WEEKLY' &&
                    <div className={`flex flex-col gap-4`}>
                        <p>Current check periods:</p>

                        {checkPeriodsForChecklist && Object.values(checkPeriodsForChecklist).length > 0 ?
                            Object.values(checkPeriodsForChecklist).map((checklistPeriod: any, index: number) => {
                                return <ChecklistSummary
                                    key={index}
                                    checklistId={checklistPeriod.ChecklistId}
                                    thisChecklist={thisChecklist}
                                    checkPeriodId={checklistPeriod.Id}
                                />
                            }) :

                            <RedAlert>No active check periods for this checklist</RedAlert>}

                    </div>}

                {thisChecklist && checklistFrequency === 'ADHOC' &&
                    <div className={`flex flex-col gap-4`}>

                        {!assetIdNeeded && <p>You are about to start this ad hoc checklist. Are you ready?</p>}
                        {assetIdNeeded && <AssetPicker assetTypeId={thisChecklist.AssetTypeId} checklistId={thisChecklist.Id} startAdhocChecklist={startAdhocChecklist}></AssetPicker>}
                        {thisChecklist && !assetIdNeeded && <Button
                            onClick={startAdhocChecklist}
                            text={'Get started'}
                            size={'big'}
                            variant={`primary`}
                        />}
                    </div>}



            </div>
        </div>


    </div>
}

function AssetPicker(
    { assetTypeId, checklistId }: AssetPickerProps
) {

    const navigate = useNavigate()

    const startAssetChecklistFromPicker = (assetId: string) => {
        setShowModal({ "spinner": 'Saving...' })
        const payload = JSON.stringify({
            action: "checklistsClientApp",
            subAction: "startAdhocChecklist",
            checklistId: checklistId,
            assetId: assetId,
        })
        sendMessageToWebsocket(payload)

        // when table data is updated with a check period for this checklist,
        // navigate to the checklist
        const unsubscribe = subscribe("tableUpdateItemAdded", data => {
            if (
                data.tableName === 'ChecklistPeriods' &&
                data.newRecord &&
                data.newRecord.Id &&
                data.newRecord.ChecklistId === checklistId
            ) {
                setShowModal(null)
                navigate(`/checklists/${data.newRecord.Id}`)
                unsubscribe()
            }
        })
    }

    const {
        tableData,
        setShowModal,
        sendMessageToWebsocket,
        assetIdsToFetch,
        setAssetIdsToFetch
    } = useContext(GlobalContext)

    // useEffect(() => {
    //     const payload = JSON.stringify({
    //         action: "checklistsClientApp",
    //         subAction: "getAssetIdsByChecklistId",
    //         checklistId: checklistId,
    //     })
    //     sendMessageToWebsocket(payload)

    //     const unsubscribe = subscribe("returnAssetIdsByChecklistId", data => {
    //       if (data.checklistId === checklistId && data.assetIds){

    //         console.log(data)
    //       }
    //       unsubscribe()
    //   })
    // }, [])



    let assets_of_type: any = {}

    if (tableData['Assets']) {
        for (const [asset_id, asset_details] of Object.entries(tableData['Assets'])) {
            //@ts-ignore
            if (asset_details['AssetTypeId'] as string == assetTypeId) {
                assets_of_type[asset_id] = asset_details
            }
        }
    }

    const any_assets = tableData['Assets'] && Object.keys(tableData['Assets']).length > 0

    return <>
        {!any_assets && <Spinner><p>Loading assets...</p></Spinner>}
        {any_assets && <>Choose an asset to start the checklist
            {
                <ul>
                    {Object.entries(assets_of_type).map(([assetId, assetDetails]: [string, any]) => (
                        <p key={assetId}>
                            <Button
                                onClick={() => { startAssetChecklistFromPicker(assetId) }}
                                text={assetDetails['AssetName']}
                                size={'big'}
                                variant={`primary`}
                            /> &nbsp;
                        </p>



                    ))}
                </ul>
            }
        </>}

    </>
}

export default StartChecklist