
import React, { useContext, Children, useState, useEffect } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'
import RedAlert from '../ui-elements/RedAlert'
import Modal from '../ui-elements/Modal'
import { subscribe } from '../utils/pubsub'
import { useVideo } from '../context/video/VideoContext'

// question types
import QuestionYesOrNo from './QuestionYesOrNo'
import QuestionText from './QuestionText'
import QuestionFridgeTemperature from './QuestionFridgeTemperature'
import QuestionPhoto from './QuestionPhoto'
import QuestionSelectMultiple from './QuestionSelectMultiple'
import QuestionSelectOne from './QuestionSelectOne'
import { b64toBlob } from '../utils/b64toBlob'
import QuestionConfirmPage from './QuestionConfirmPage'
import { findNextQuestionUrl } from '../utils/findNextQuestionUrl'
import { getChecklistProgress } from '../utils/getChecklistProgress'
import { checkIfAnswerIsLocked } from '../utils/checkIfAnswerIsLocked'
import Card from '../ui-elements/Card'
import QuestionNumber from './QuestionNumber'
import QuestionMultipleTextFields from './QuestionMultipleTextFields'
import HeaderDetails from '../shared-components/HeaderDetails'
import { fridgeName } from '../utils/fridgeName'
import { getAssetIdFromUrl } from '../utils/getAssetIdFromUrl'
import QuestionPhotoAndReading from './QuestionPhotoAndReading'
import QuestionCustomFormat from './QuestionCustomFormat'



type Props = {
  showAnswerConfirmation: boolean
  children?: any
}

function QuestionPage({
  showAnswerConfirmation = false,
  children
}: Props) {

  const {
    tableData,
    setShowModal,
    sendMessageToWebsocket,
    isVideoMode,
    setIsVideoMode,
    setIsVideoPlaying,
    loggedIn,
    userData,
    isSignoffRequired,
    checkPeriodIdsForActiveCheckists,
    processData
  } = useContext(GlobalContext)

  const navigate = useNavigate()
  const { answerId, checkPeriodId } = useParams()
  const [isQuestionLocked, setIsQuestionLocked] = useState(false)
  const [lockedUntilText, setLockedUntilText] = useState('')
  const [isAnswerPending, setIsAnswerPending] = useState(false)
  const [thisQuestion, setThisQuestion] = useState<null | ObjectStringKeyAnyValue>(null)
  const [questionAnswerValue, setQuestionAnswerValue] = useState<null | string | ObjectStringKeyAnyValue | Array<string>>(null)
  const [questionAnswerQRProof, setQuestionAnswerQRProof] = useState<null | string>(null)
  const [questionAnswer, setQuestionAnswer] = useState<null | ObjectStringKeyAnyValue>(null)
  const [answerNote, setAnswerNote] = useState<null | string>(null)
  const [answerPhoto, setAnswerPhoto] = useState<null | string>(null)
  // const [titleText, setTitleText] = useState<string>('')
  const [subheadingText, setSubheadingText] = useState<string>('')
  const [isAnswerManuallyEdited, setIsAnswerManuallyEdited] = useState(false)
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const [nextButtonText, setNextButtonText] = useState('')
  const [nextButtonAction, setNextButtonAction] = useState<null | AnyFunction>(null)
  const [nextStepUrl, setNextStepUrl] = useState<null | string>(null)
  const [checklistProgress, setChecklistProgress] = useState<null | ObjectStringKeyAnyValue>(null)
  const [jpegString, setJpegString] = useState<string | null>(null)
  const { captureAsJPEGString, straightenedImageUrl, straightenedImageNoQRUrl, QRCodesPresent,
    setQRCodesPresent, clearCameraStateVariables } = useVideo()
  const [fridgeHasDigitalDisplay, setFridgeHasDigitalDisplay] = useState<null | boolean>(null)
  const [footerButtons, setFooterButtons] = useState<Array<string>>(['back'])
  const [doesScannedQrCodeMatchAssetId, setDoesScannedQrCodeMatchAssetId] = useState(false)


  const checklistId = checkPeriodId && tableData && tableData.CheckPeriods && tableData.CheckPeriods[checkPeriodId] && tableData.CheckPeriods[checkPeriodId]['ChecklistId'] ? tableData.CheckPeriods[checkPeriodId]['ChecklistId'] : null

  const thisCheckPeriodSignoff = checkPeriodId && tableData && tableData.CheckPeriods && tableData.CheckPeriods[checkPeriodId] && tableData.CheckPeriods[checkPeriodId]['Signoff'] ? tableData.CheckPeriods[checkPeriodId]['Signoff'] : null

  const thisCheckPeriod = checkPeriodId && tableData && tableData.CheckPeriods && tableData.CheckPeriods[checkPeriodId] ? tableData.CheckPeriods[checkPeriodId] : null

  const processIdForThisChecklist = thisCheckPeriod && thisCheckPeriod.ProcessDefinitionId ? thisCheckPeriod.ProcessDefinitionId : null




  
    // useEffect(() => {
    //   console.log(footerButtons)
    // }, [footerButtons])



    useEffect(() => {
      // check if it's locked
      if (questionAnswer) {
        checkIfAnswerIsLocked(questionAnswer, setIsQuestionLocked, setLockedUntilText)
        const intervalId = setInterval(() => {
          checkIfAnswerIsLocked(questionAnswer, setIsQuestionLocked, setLockedUntilText)
        }, 1000)
        return () => clearInterval(intervalId)
      }
    }, [questionAnswer])





  useEffect(() => {
    if (doesScannedQrCodeMatchAssetId && thisQuestion && thisQuestion.QuestionFormat === 'PhotoAndReading') {
      const newFooterButtons = [...footerButtons].filter(button => button !== 'takePhoto')

      console.log('🔥')
      setFooterButtons([...newFooterButtons, 'saveWithNote', 'save', 'retakePhoto'])
    }

  }, [jpegString])

  useEffect(() => {
    handleSetThisQuestion()

    if (tableData && checkPeriodId) {
      const progress = getChecklistProgress(checkPeriodId, tableData)
      if (progress) {
        setChecklistProgress(progress)
      }
    }

    if (thisQuestion && checkPeriodId && tableData) {
      const nextStep = findNextQuestionUrl(answerId, checkPeriodId, tableData, '')
      nextStep && setNextStepUrl(nextStep)
    }


  }, [tableData, answerId, thisQuestion, checkPeriodId])



  useEffect(() => {
    // clear error message when answer gets updated
    setErrorMessage(null)
  }, [questionAnswerValue])

  useEffect(() => {
    // set the footer buttons
    displayFooterButtons()

  }, [questionAnswer, questionAnswerValue, thisQuestion])


  const displayFooterButtons = () => {
    if (thisQuestion &&
      thisQuestion.QuestionFormat !== 'Photo' &&
      thisQuestion.QuestionFormat !== 'FridgeTemperature' &&
      thisQuestion.QuestionFormat !== 'PhotoAndReading'
    ) {
      if (questionAnswerValue && questionAnswer && questionAnswer.Pending === true) {
        setFooterButtons(['back', 'saveWithNote', 'save'])
      }
      else if (questionAnswer && questionAnswer.Pending === false) {
        // console.log('🥝')
        setFooterButtons(['back', 'next'])
      } else {
        setFooterButtons(['back'])
      }
    }
  }



  const handleSetThisQuestion = () => {
    if (
      answerId &&
      tableData &&
      tableData.ChecklistQuestions &&
      tableData.ChecklistAnswers
    ) {

      if (tableData.ChecklistAnswers[answerId]) {
        let subtitleTextString = ''
        const questionAnswerObject = tableData.ChecklistAnswers[answerId]
        setQuestionAnswer(questionAnswerObject)
        //console.log("Setting question answer in question page because handleSetThisQuestion was called")
        setQuestionAnswerValue(questionAnswerObject.Answer)
        setIsAnswerPending(questionAnswerObject.Pending)
        const questionIdForThisAnswer = questionAnswerObject.QuestionId
        if (tableData.ChecklistQuestions[questionIdForThisAnswer]) {
          const questionForThisAnswer = tableData.ChecklistQuestions[questionIdForThisAnswer]
          setThisQuestion(questionForThisAnswer)

          // if it's a fridge temperature question, check if it's got a digital display
          if (questionForThisAnswer.FridgeId) {
            if (
              tableData.Assets &&
              tableData.Assets[questionForThisAnswer.FridgeId] &&
              tableData.Assets[questionForThisAnswer.FridgeId].HasDigitalReadout === true
            ) {
              //console.log(tableData.Assets[questionForThisAnswer.FridgeId])
              setFridgeHasDigitalDisplay(true)
            } else {
              setFridgeHasDigitalDisplay(false)
            }

            // set the subtitle text
            if (QRCodesPresent && QRCodesPresent.length > 0) {
              let assetIdFromDataUrl = getAssetIdFromUrl(QRCodesPresent[0])
              if (assetIdFromDataUrl === questionForThisAnswer.FridgeId) {
                setDoesScannedQrCodeMatchAssetId(true)
                if (questionForThisAnswer.QuestionFormat === 'PhotoAndReading') {
                  subtitleTextString = `✅ QR code detected. Take a photo of the thermometer.`
                } else {
                  subtitleTextString = `✅ QR code detected`
                }
              }
            }
          }


          // set the subheading text if its a follow up
          if (questionForThisAnswer.parentQuestionId && tableData.ChecklistQuestions[questionForThisAnswer.parentQuestionId]) {
            const parentQuestionText = tableData.ChecklistQuestions[questionForThisAnswer.parentQuestionId].QuestionText
            subtitleTextString = `${subtitleTextString} Follow up question for: ${parentQuestionText ? `"${parentQuestionText}"` : `ID #:${questionForThisAnswer.parentQuestionId}`}`
          }
        }

        // set the subheading text if its a repeated
        if (questionAnswerObject.repeatAnswerAfter && questionAnswerObject.repeatAnswerAfter.type === 'numberOfTimes') {
          subtitleTextString = `${subtitleTextString} 🔁 Sample ${parseInt(questionAnswerObject.repeatAnswerAfter.number) + 1}.`
        }

        // set the subheading text if its a delayed question
        if (questionAnswerObject.repeatAnswerAfter && questionAnswerObject.repeatAnswerAfter.type === 'timeDelay') {
          subtitleTextString = `${subtitleTextString} 🔁 Sampling after ${questionAnswerObject.repeatAnswerAfter.number < 60 ? ` ${questionAnswerObject.repeatAnswerAfter.number} minutes ` : ` ${Math.round(questionAnswerObject.repeatAnswerAfter.number / 60 * 10) / 10} hours `}.`
        }

        // set the title text string
        let titleTextString = thisQuestion ? thisQuestion.QuestionText : 'Unknown Question'
        if (thisQuestion && thisQuestion.FridgeId) {
          if (thisQuestion.QuestionFormat === 'PhotoAndReading') {
            titleTextString = `${fridgeName(thisQuestion.FridgeId, tableData)}`
          } else {
            titleTextString = `Temperature check for ${fridgeName(thisQuestion.FridgeId, tableData)}.`
          }
        }

        // if a custom data format is required, add it to the subtitle text
        if (thisQuestion && thisQuestion.CustomDataFormat && thisQuestion.CustomDataFormat.RegexName) {
          subtitleTextString = `${subtitleTextString} Required data format: ${thisQuestion.CustomDataFormat.RegexName}`
        }


        // setTitleText(titleTextString)
        setSubheadingText(subtitleTextString)
      }
    }
  }


  const handleChangeSelectMultiple = (value: string) => {
    let newAnswerArray: string[]

    // if existing questionAnswer is not an array, start a new array
    if (questionAnswerValue && questionAnswerValue instanceof Array) {
      newAnswerArray = [...questionAnswerValue]
    } else {
      newAnswerArray = []
    }
    // add the value if it doesn't already exist
    // remove the value if it does exist already
    if (newAnswerArray.includes(value)) {
      newAnswerArray = newAnswerArray.filter((option) => option !== value)
    } else {
      newAnswerArray.push(value)
    }
    setQuestionAnswerValue(newAnswerArray)
  }


  const handleChangeMultiText = (key: string, value: string) => {

    let newAnswerObject: ObjectStringKeyAnyValue = {}
    if (questionAnswerValue && questionAnswerValue instanceof Object) {
      newAnswerObject = { ...questionAnswerValue }
    }
    if (value) {
      newAnswerObject[key] = value
    } else {
      delete newAnswerObject[key]
    }


    setQuestionAnswerValue(newAnswerObject)
  }


  const validate = async (behaviour: any) => {
    let isValid = true

    if (!questionAnswerValue && thisQuestion?.QuestionFormat !== 'Photo') {
      setErrorMessage('Please select or enter a valid answer')
    }

    if (questionAnswerValue && thisQuestion && thisQuestion.CustomDataFormat && thisQuestion.CustomDataFormat.RegexString) {
      let emailRegex = new RegExp(thisQuestion.CustomDataFormat.RegexString)
      let doesAnswerMatchPattern = emailRegex.test(`${questionAnswerValue}`)
      if (!doesAnswerMatchPattern) {
        isValid = false
        const formatName = thisQuestion.CustomDataFormat.RegexName
        setErrorMessage(`Answer does not match the required format` + (formatName ? ` (${formatName})` : ''))
      }
    }

    if (
      thisQuestion?.QuestionFormat === 'MultipleTextFields' &&
      (!questionAnswerValue ||
        Object.keys(questionAnswerValue).length === 0 ||
        Object.keys(questionAnswerValue).length < thisQuestion.QuestionFieldNames.length
      )
    ) {
      isValid = false
      setErrorMessage('Please enter an answer for each field')
    }

    if (thisQuestion?.QuestionFormat === 'Photo' && !answerPhoto) {
      isValid = false
      setErrorMessage('Please take a picture')
    }

    if (isValid) {
      //console.log(`✅ validating answer for question id: ${thisQuestion?.Id}`)
      await saveAnswer(behaviour)
    }
  }

  const saveNote = async () => {
    console.log(`saving note "${answerNote}" for answer id: ${answerId}`)
    if (answerId && answerNote) {
      setShowModal({ "spinner": 'Saving...' })
      const payload: ObjectStringKeyAnyValue = {
        action: "checklistsClientApp",
        subAction: "saveAnswerNote",
        answerId: `${answerId}`,
        answerNote: answerNote
      }
      // if a user is logged in as a guest, add their name to the data
      if (loggedIn === 'guest') {
        payload['GuestUserName'] = userData.name
      }
      sendMessageToWebsocket(JSON.stringify(payload))
      const unsubscribe = subscribe("tableUpdateItemModified", data => {
        if (data.newRecord && data.newRecord.Id === answerId) {
          setShowModal(null)
          setAnswerNote(null)
          unsubscribe()
        }
      })
    }
  }

  const saveAnswer = async (behaviour: any) => {
    setErrorMessage(null)
    if (answerId) {
      setFooterButtons(['back'])
      setQRCodesPresent(null)
      setShowModal({ "spinner": 'Saving...' })

      // send answer to websocket
      const answerPayload: ObjectStringKeyAnyValue = {
        action: "checklistsClientApp",
        subAction: "saveAnswerToQuestion",
        answerId: `${answerId}`,
        values: {
          Answer: questionAnswerValue
        }
      }
      // add an answer note if there is one
      if (answerNote) answerPayload.values['AnswerNote'] = answerNote

      // if a user is logged in as a guest, add their name to the data
      if (loggedIn === 'guest') {
        answerPayload['GuestUserName'] = userData.name
      }

      // if its a fridge temp, include if it's manually edited 
      if (thisQuestion?.QuestionFormat === 'FridgeTemperature') {
        answerPayload.values['isAnswerManuallyEdited'] = isAnswerManuallyEdited
      }


      // navigate once answer is saved

      // if there is a photo, upload it
      if (answerPhoto) {
        const timestamp = `${Date.now()}`
        let fileName = `${answerId}-${timestamp}.jpg`
        await handleImageUpload(answerId, answerPhoto, fileName, timestamp, behaviour)
      }
      if (questionAnswerQRProof) {
        const timestamp = `${Date.now()}`
        let fileName = `${answerId}-qrproof-${timestamp}.jpg`
        await handleImageUpload(answerId, questionAnswerQRProof, fileName, timestamp, behaviour, 'QRProofFileName')
      }

      // console.log(payload)
      sendMessageToWebsocket(JSON.stringify(answerPayload))
      // navigate once answer is saved
      const unsubscribe = subscribe("answerUpdateComplete", data => {
        if (data.answerId === answerId) {
          unsubscribe()
          setShowModal(null)
          setJpegString(null)
          setQuestionAnswerValue(null)
          setAnswerNote(null)
          setAnswerPhoto(null)
          const nextUrl = (findNextQuestionUrl(answerId, checkPeriodId, tableData, behaviour))
          navigate(nextUrl)
        }
      })

    } else {
      setErrorMessage('Sorry - could not save this answer')
    }
  }


  const handleImageUpload = async (answerId: string, dataString: string, fileName: string, timestamp: string, behaviour: any, image_key = "ImageFileName") => {
    const payload = JSON.stringify({
      action: "checklistsClientApp",
      subAction: "requestPresignedUrl",
      fileName: fileName,
      waitingForUploadUrlId: timestamp
    })
    sendMessageToWebsocket(payload)
    const response = await new Promise<void>((resolve, reject) => {
      const unsubscribe = subscribe("returnPresignedUrl", async data => {
        if (data.waitingForUploadUrlId === timestamp) {
          unsubscribe()
          const prefix = 'data:image/jpeg;base64,'
          const fileBody = dataString!.split(prefix)[1]
          const file = new File([b64toBlob(fileBody)], timestamp, { type: "image/jpeg" })
          await fetch(data.signedUrl, {
            method: 'PUT',
            body: file
          })
            .then(() => {
              // send answer to websocket
              let payload_obj: { action: string; subAction: string; answerId: string; values?: { [key: string]: string } } = {
                action: "checklistsClientApp",
                subAction: "saveAnswerToQuestion",
                answerId: `${answerId}`,
              }
              payload_obj.values = {}
              payload_obj.values[`${image_key}`] = fileName
              const payload = JSON.stringify(payload_obj)
              sendMessageToWebsocket(payload)
              resolve()
            }).catch((error) => {
              console.error('Error:', error)
            })
        }
      })
    })
  }

  const retakePhoto = () => {
    setAnswerPhoto && setAnswerPhoto(null)
    setJpegString(null)
    //console.log("Retake photo: turning video on 2")
    setIsVideoPlaying(true)
    setIsVideoMode(true)
    setQRCodesPresent(null)
    clearCameraStateVariables()
    setQuestionAnswerValue(null)
  }


  const capturePhoto = () => {
    //console.log("Captured photo")
    const jpgData = captureAsJPEGString(600)
    setAnswerPhoto && setAnswerPhoto(jpgData || null)
    setJpegString && setJpegString(jpgData || null)
    //console.log("Turning video off 1")
    setIsVideoPlaying(false)
    setIsVideoMode(false)
  }



  const resetQuestion = (answerId: string) => {
    setShowModal({ "spinner": 'Saving...' })
    const payload = JSON.stringify({
      action: "checklistsClientApp",
      subAction: "resetAnswer",
      answerId: answerId
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("tableUpdateItemModified", data => {
      //console.log(`🪼 ${JSON.stringify(data)}`)
      if (data.newRecord && data.newRecord.Id && data.newRecord.Id === answerId) {
        setShowModal(null)
        navigate(`/checklists/${checkPeriodId}`)
        unsubscribe()
      }
    })
  }





  return <div
    className={`w-full flex-1 h-full flex flex-col justify-between gap-2`}
  >


    <div className={`w-full flex flex-col items-center`}>

      <HeaderDetails
        instructionsText={thisQuestion && thisQuestion.QuestionInstructions}
        subheadingText={subheadingText}
        thisQuestion={thisQuestion}
      />



      {!tableData && <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}><Spinner><p>Loading...</p></Spinner></div>}

      {tableData && <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}>




        {!thisQuestion && <div>
          <RedAlert>Invalid question</RedAlert>
        </div>}


        {thisQuestion && !isAnswerPending && showAnswerConfirmation &&
          <QuestionConfirmPage
            question={thisQuestion}
            answerId={answerId}
            answerNote={answerNote}
            setAnswerNote={setAnswerNote}
            saveNote={saveNote}
            setJpegString={setJpegString}
            resetQuestion={resetQuestion}
            setAnswerPhoto={setAnswerPhoto}
          />}


        {isQuestionLocked && thisQuestion && <Card>
          <div className={`flex flex-col items-center`}>
            <p className={`text-4xl`}>🔐</p>
            <h5 className={`font-righteous text-brandblue text-lg`}>This question is locked</h5>
            <p className={``}>{lockedUntilText}</p>
          </div>
        </Card>}

        {!isQuestionLocked && thisQuestion && !showAnswerConfirmation &&
          <div className={`w-full mb-4`}>

            {thisQuestion.QuestionFormat === 'YesOrNo' && <QuestionYesOrNo
              setQuestionAnswer={setQuestionAnswerValue}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
            />}

            {thisQuestion.QuestionFormat === 'Text' && <QuestionText
              setQuestionAnswer={setQuestionAnswerValue}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
            />}

            {thisQuestion.QuestionFormat === 'MultipleTextFields' && <QuestionMultipleTextFields
              setQuestionAnswer={setQuestionAnswerValue}
              question={thisQuestion}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              handleChangeMultiText={handleChangeMultiText}
              defaultValue={questionAnswerValue}
            />}

            {thisQuestion.QuestionFormat === 'Number' && <QuestionNumber
              setQuestionAnswer={setQuestionAnswerValue}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
            />}

            {thisQuestion.QuestionFormat === 'Photo' && <QuestionPhoto
              answerPhoto={answerPhoto}
              setAnswerPhoto={setAnswerPhoto}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              answerNote={answerNote}
              setAnswerNote={setAnswerNote}
              defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
              jpegString={jpegString}
              setJpegString={setJpegString}
              setFooterButtons={setFooterButtons}
              isAnswerPending={isAnswerPending}
            />}




            {
              thisQuestion.QuestionFormat === 'FridgeTemperature' &&
              <QuestionFridgeTemperature
                questionId={thisQuestion.Id}
                checklistId={checklistId}
                defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
                fridgeId={thisQuestion.FridgeId}
                questionAnswer={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
                setQuestionAnswer={setQuestionAnswerValue}
                answerNote={answerNote}
                setAnswerNote={setAnswerNote}
                answerPhoto={answerPhoto}
                setAnswerPhoto={setAnswerPhoto}
                isAnswerManuallyEdited={isAnswerManuallyEdited}
                setIsAnswerManuallyEdited={setIsAnswerManuallyEdited}
                jpegString={jpegString}
                setJpegString={setJpegString}
                fridgeHasDigitalDisplay={fridgeHasDigitalDisplay}
                checkPeriodId={checkPeriodId}
                setFooterButtons={setFooterButtons}
                isAnswerPending={isAnswerPending}
              />}


            {
              thisQuestion.QuestionFormat === 'PhotoAndReading' &&
              <QuestionPhotoAndReading
                questionId={thisQuestion.Id}
                checklistId={checklistId}
                defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
                assetId={thisQuestion.AssetId}
                questionAnswer={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
                setQuestionAnswer={setQuestionAnswerValue}
                answerNote={answerNote}
                setAnswerNote={setAnswerNote}
                answerPhoto={answerPhoto}
                setAnswerPhoto={setAnswerPhoto}
                isAnswerManuallyEdited={isAnswerManuallyEdited}
                setIsAnswerManuallyEdited={setIsAnswerManuallyEdited}
                jpegString={jpegString}
                setJpegString={setJpegString}
                assetHasDigitalDisplay={false}
                checkPeriodId={checkPeriodId}
                setQuestionAnswerQRProof={setQuestionAnswerQRProof}
                setFooterButtons={setFooterButtons}
              />}

            {
              thisQuestion.QuestionFormat === 'AssetReading' &&
              <QuestionNumber
                setQuestionAnswer={setQuestionAnswerValue}
                questionId={thisQuestion.Id}
                checklistId={checklistId}
                defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
              />}



            {thisQuestion.QuestionFormat === 'SelectMultiple' && <QuestionSelectMultiple
              handleChangeSelectMultiple={handleChangeSelectMultiple}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerValue !== null && questionAnswerValue instanceof Array ? questionAnswerValue : ''}
              questionOptions={thisQuestion.QuestionOptions}
            />}

            {thisQuestion.QuestionFormat === 'SelectOne' && <QuestionSelectOne
              setQuestionAnswer={setQuestionAnswerValue}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
              questionOptions={thisQuestion.QuestionOptions}
            />}

            {thisQuestion.QuestionFormat === 'CustomDataFormat' && thisQuestion.CustomDataFormat && thisQuestion.CustomDataFormat.RegexString && <QuestionCustomFormat
              setQuestionAnswer={setQuestionAnswerValue}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerValue !== null && !(questionAnswerValue instanceof Object) ? questionAnswerValue : ''}
            />}


          </div>}

        {errorMessage && <Modal
        // showCloseButton={true}
        // setShowModal={setErrorMessage}
        // closeButtonValue={null}
        >
          <div className={`flex flex-col gap-4`}>
            <RedAlert icon={true} size={'large'}>{errorMessage}</RedAlert>
            <Button
              onClick={() => setErrorMessage(null)}
              text={'Ok'}
              size={'big'}
            /></div>
        </Modal>}

      </div>}
    </div>




    <div className={`
  ${isVideoMode ? '' : ' border-t-2 border-gray-300 bg-white '} 
  fixed bottom-0 w-full flex flex-col items-center justify-center `}>

      {/* <p className={`text-xs text-center text-gray-300`}>{findNextQuestionUrl(answerId, checkPeriodId, tableData, '')}</p> */}



      <div className={`max-w-3xl w-full px-5 py-3 flex flex-row gap-2`}>




        {footerButtons.includes('back') ? <Button
          internalLinkUrl={processIdForThisChecklist ? `/process/${processIdForThisChecklist}/` : `/checklists/${checkPeriodId}/`}
          text={`Back`}
          disabled={false}
          variant={'primary'}
        /> : '' }

        {footerButtons.includes('takePhoto') ? <Button
          onClick={capturePhoto}
          text={`📸 Take photo`}
          disabled={false}
          variant={'primary'}
        /> : '' }

        {footerButtons.includes('retakePhoto') ? <Button
          onClick={retakePhoto}
          text={`Retake`}
          disabled={false}
          variant={'primary'}
        /> : '' }

        {footerButtons.includes('saveWithNote') ? <Button
          onClick={() => validate('confirm')}
          text={`Save with note`}
        /> : '' }

        {footerButtons.includes('save') ? <Button
          onClick={() => validate(null)}
          text={`Save`}
        /> : '' }

        {footerButtons.includes('next') ? <Button
          internalLinkUrl={nextStepUrl || '/checklists/'}
          text={`Next`}
          disabled={false}
          variant={`primary`}
        /> : '' }



        {/* 

        {!(jpegString && isAnswerPending) && <Button
          internalLinkUrl={`/checklists/${checkPeriodId}/`}
          text={`Back`}
          disabled={false}
          variant={'primary'}
        />}


        {checkPeriodId && !answerPhoto && isAnswerPending && thisQuestion &&
          (
            thisQuestion.QuestionFormat === 'Photo' ||
            (thisQuestion.QuestionFormat === 'PhotoAndReading' && doesScannedQrCodeMatchAssetId)
          )
          && <Button
            onClick={capturePhoto}
            text={`📸 Take photo`}
            disabled={false}
            variant={'primary'}
          />}




        {jpegString && isAnswerPending && <Button
          onClick={retakePhoto}
          text={`Retake`}
          disabled={false}
          variant={'primary'}
        />}

        {
          isAnswerPending &&
          !showAnswerConfirmation &&
          !isQuestionLocked &&
          (thisQuestion?.QuestionFormat !== 'Photo' || thisQuestion?.QuestionFormat === 'Photo' && jpegString) &&
          <Button
            onClick={() => validate('confirm')}
            text={`Save with note`}
          />}

        {
          isAnswerPending &&
          !showAnswerConfirmation &&
          !isQuestionLocked &&
          (thisQuestion?.QuestionFormat !== 'Photo' || thisQuestion?.QuestionFormat === 'Photo' && jpegString) &&
          <Button
            onClick={() => validate(null)}
            text={`Save`}
          />}

        {!isAnswerPending && nextStepUrl && <Button
          internalLinkUrl={nextStepUrl}
          text={`Next`}
          disabled={false}
          variant={`primary`}
        />} */}


      </div>
    </div>

  </div>
}
export default QuestionPage